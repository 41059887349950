import React from 'react';

import Layout from '../components/Layout';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCocktail, faCross, faPeopleGroup, faUtensils, faHeart, faFilterCircleXmark, faCarSide, faCake, faPersonFalling, faChampagneGlasses, faPeoplePulling } from '@fortawesome/free-solid-svg-icons'

import { StaticImage } from 'gatsby-plugin-image';

const Events = (props) => (
  <Layout fullMenu location={props.location} title="Events">
    <article id="main">
      <header>
        <h2>Events</h2>
        <p>Saturday, May 27th, 2023</p>
      </header>
      <section className="wrapper style8 events">
        <div className="inner">
          <div className="row">
            <VerticalTimeline 
              lineColor={ 'black' }
            >
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentArrowStyle={{ borderRight: '7px solid  rgb(0, 0, 0)' }}
                date="Saturday May 27th 2:00pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faCross} />
              }
              >
                <h3 className="vertical-timeline-element-title">Wedding Ceremony</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">Vietnamese Martyrs Catholic Church</h4> */}
                <p>
                  Holy Vietnamese Martyrs Catholic Church
                </p>
                <a
                  href="https://goo.gl/maps/tjBtw7dAvQNeiwM29"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  1107 E Yager Ln
                  <br />
                  Austin, TX 78753
                </a>
                <p>
                  The part where we say, "I do."
                </p>
                <span className="image fit eventimage">
                  <StaticImage
                    src="../assets/images/church.jpg"
                    alt="church"
                    placeholder="blurred"
                    imgStyle={{marginTop:"10px"}}
                  />
                </span>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="5:30pm"
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faCocktail} />}
              >
                <h3 className="vertical-timeline-element-title">Cocktail Hour & Photos</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Celebrino Event Center
                </p>
                <a
                  href="https://g.page/CelebrinoEventCenter?share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  51 Co Rd 107
                  <br />
                  Georgetown, TX 78626
                </a>
                <p>
                  Come grab a drink and snap a photo with us!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="6:30pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faPeopleGroup} />}
              >
                <h3 className="vertical-timeline-element-title">Grand Entrance and Introductions</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Meet the bridal party and the Luu/Pham families. #LuuPhamily
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="6:55pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faUtensils} />}
              >
                <h3 className="vertical-timeline-element-title">Serving of Dinner</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Dinner will be provided by Kim Son. Mời and hope you enjoy!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="7:00pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faPeoplePulling} />}
              >
                <h3 className="vertical-timeline-element-title">First Dance</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Make sure you're ready to record this intimate first dance. There will also be a father/daughter and mother/son dance.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="7:15pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faCake} />}
              >
                <h3 className="vertical-timeline-element-title">Cutting of Cake</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Don't forget to save some room for dessert! This cake will include pandan and durian flavors... The best of both worlds!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="7:45pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faChampagneGlasses} />}
              >
                <h3 className="vertical-timeline-element-title">Chào Bàn</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  It's time for us to make our rounds. Take a shot with us! 
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="8:30pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faPersonFalling} />}
              >
                <h3 className="vertical-timeline-element-title">Dance Floor Opens</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Let's get this party started! Show us your best moves.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="10:30pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faFilterCircleXmark} />}
              >
                <h3 className="vertical-timeline-element-title">Bar Closes</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  It's time to call an uber.
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date="10:50pm"
                iconStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                contentStyle={{ background: 'rgb(247,247,243)', color: '#000' }}
                icon={<FontAwesomeIcon icon={faCarSide} />}
              >
                <h3 className="vertical-timeline-element-title">Send Off</h3>
                {/* <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4> */}
                <p>
                  Thank you so much for being part of our special day! We hope you had fun!
                </p>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                iconStyle={{ background: 'rgb(235, 189, 204)', color: '#fff' }}
                icon={<FontAwesomeIcon icon={faHeart} />}
              />
            </VerticalTimeline>
            
          </div>
          {/* <div className="row">
            <div className="col-6 col-12-medium">
              <h3>Wedding Ceremony</h3>
              <p>
                Vietnamese Martyrs Catholic Church - <b>2:00pm</b>
                <br />
                <a
                  href="https://goo.gl/maps/tjBtw7dAvQNeiwM29"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  1107 E Yager Ln
                  <br />
                  Austin, TX 78753
                </a>
                <br />
              </p>
            </div>
            <div className="col-6 col-12-medium">
              <span className="image fit eventimage">
                <StaticImage
                  src="../assets/images/church.jpg"
                  alt="church"
                  placeholder="blurred"
                />
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-12-medium">
              <h3>Reception</h3>

              <p>
                Celebrino Event Center - <b>5:30pm</b>
                <br />
                <a
                  href="https://g.page/CelebrinoEventCenter?share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  51 Co Rd 107
                  <br />
                  Georgetown, TX 78626
                </a>
                <br />
              </p>
            </div>
            <div className="col-6 col-12-medium">
              <span className="image fit eventimage">
                <StaticImage
                  src="../assets/images/celebrino.jpeg"
                  alt="reception"
                  placeholder="blurred"
                />
              </span>
            </div>
          </div> */}

          <hr />

          {/* <h4>Lodging</h4>
          <p>We have reserved rooms at this hotel, please let them know</p> */}
        </div>
      </section>
    </article>
  </Layout>
);

export default Events;
